import * as React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => {
  return (
    <Layout>
      <SEO title="Siden blev ikke fundet" />
      <div className="container mx-auto py-48 text-center">
        <h1 className="heading-xl">Hov, hvor er du på vej hen?</h1>
        <p className="text-xl">
          Det ser ud til at du søgte en side der ikke findes.
        </p>
      </div>
    </Layout>
  )
}

export default NotFoundPage
